import React from 'react'
import {
	Box,
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'

export const PlantTreeModal = ({ isOpen, onClose }) => {
	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="lg">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image
							src={'/assets/images/graphics/vidday-tree-planting.png'}
							width="240px"
							height="auto"
							alt="Plant a Tree"
						/>
						<Heading size="lg" variant="hero">
							A Gift That Gives Back
						</Heading>
					</VStack>
					<Box display="grid" textAlign="center">
						<Text mt="0.5rem">
							By planting trees on their behalf, you're not just giving a thoughtful gift — you’re making
							a positive impact on the world.
						</Text>
						<Text mt="0.5rem">
							Feel good knowing your gift will leave a lasting legacy for both the recipient and the
							planet.
						</Text>
					</Box>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button onClick={onClose}>Close</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
