import React from 'react'
import { useNProgress } from '@tanem/react-nprogress'
import { Spinner as ChakraSpinner } from '@chakra-ui/react'

const Bar = ({ animationDuration, progress }) => {
	return (
		<div
			style={{
				background: '#0187ff',
				height: 2,
				left: 0,
				marginLeft: `${(-1 + progress) * 100}%`,
				position: 'fixed',
				top: 0,
				transition: `margin-left ${animationDuration}ms linear`,
				width: '100%',
				zIndex: 2147483647,
			}}>
			<div
				style={{
					boxShadow: '0 0 10px #0187ff, 0 0 5px #0187ff',
					display: 'block',
					height: '100%',
					opacity: 1,
					position: 'absolute',
					right: 0,
					transform: 'rotate(3deg) translate(0px, -4px)',
					width: 100,
				}}
			/>
		</div>
	)
}

const Container = ({ animationDuration, children, isFinished }) => {
	return (
		<div
			style={{
				opacity: isFinished ? 0 : 1,
				pointerEvents: 'none',
				transition: `opacity ${animationDuration}ms linear`,
			}}>
			{children}
		</div>
	)
}

const Spinner = () => {
	return (
		<div
			style={{
				display: 'block',
				position: 'fixed',
				right: 15,
				top: 15,
				zIndex: 2147483647,
			}}>
			<ChakraSpinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
			{/* <Loader strokeColor="#0187ff" size={20} /> */}
		</div>
	)
}

const Progress = ({ animationDuration, incrementDuration, isAnimating, minimum }) => {
	const { isFinished, progress } = useNProgress({
		animationDuration,
		incrementDuration,
		isAnimating,
		minimum,
	})

	return (
		<Container animationDuration={animationDuration} isFinished={isFinished}>
			<Bar animationDuration={animationDuration} progress={progress} />
			<Spinner />
		</Container>
	)
}

export default Progress
