import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { geo } from '../../../../../client/config.client'
import { updateCart } from '../../../../api/checkout/actions'
import ChooseBetweenModal from './atoms/Modal/ChooseBetweenModal'
import ConfirmOneModal from './atoms/Modal/ConfirmOneModal'
import { useCheckoutContext } from '../../../contexts/CheckoutProvider'

/**
 * Hook/Side effects to retrieve products when the event changes
 * @returns
 */
const LocationManager = () => {
	/** Retrieve Dispatch */
	const dispatch = useDispatch()

	/** Retrieve checkout location and cart state */
	const {
		location: { country: cartCountry, region: cartRegion },
		checkout: {
			location: {
				isFetching: cartLocationFetching,
				profileLocationIsFetching,
				profileLocation: { country: profileCountry, region: profileRegion },
				countries,
				ipLocation,
			},
		},
		cart: { isFetching: isFetchingCart, data: cartData },
	} = useCheckoutContext()

	const [isChooseBetweenTwo, setIsChooseBetweenTwo] = useState(false)
	const [isConfirmOneOpen, setIsConfirmOneOpen] = useState(false)
	const [chooseModalProp, setChooseModalProp] = useState({})
	const [confirmModalProp, setConfirmModalProp] = useState({})

	const check = (condition) => {
		if (condition) {
			return true
		} else {
			return false
		}
	}

	/**
	 * Fetch the user's cart for this event ID (and whenever the cart changes)
	 **/
	useEffect(() => {
		// console.log('Countries', countries)
		// console.log('Profile fetching', profileLocationIsFetching)
		// console.log('Cart', cartCountry, cartRegion)
		// console.log('IP', ipLocation?.country_code, ipLocation?.region_code)
		if (
			!cartLocationFetching &&
			!isFetchingCart &&
			!profileLocationIsFetching &&
			cartData &&
			countries &&
			Object.keys(countries).length > 0
		) {
			if (!cartCountry) {
				switch (true) {
					case check(
						profileCountry &&
							ipLocation &&
							(profileCountry !== ipLocation.country_code ||
								(profileRegion !== ipLocation.region_code && ['CA', 'US'].includes(profileCountry)))
					):
						// Open ChooseBetween modal
						console.log(`case1`)
						setChooseModalProp({
							countryOne: profileCountry,
							countryTwo: ipLocation.country_code,
							regionOne: profileRegion,
							regionTwo: ipLocation.region_code,
						})
						setIsChooseBetweenTwo(true)

						break
					case check(profileCountry):
						// Open ConfirmOne Model
						// setConfirmModalProp({ country: profileCountry, region: profileRegion })
						console.log(`case2`)
						dispatch(updateCart({ countryCode: profileCountry, regionCode: profileRegion }))
						// make sure model is closed
						setIsChooseBetweenTwo(false)
						setIsConfirmOneOpen(false)
						break
					case check(ipLocation && ipLocation.country_code):
						// Open ConfirmOne Model
						console.log(`case3`)
						setConfirmModalProp({ country: ipLocation.country_code, region: ipLocation.region_code })
						setIsConfirmOneOpen(true)
						break
					default:
						console.log(`case4`)
						dispatch(updateCart({ countryCode: geo.defaultCountryCode, regionCode: geo.defaultRegionCode }))
						// make sure model is closed
						setIsChooseBetweenTwo(false)
						setIsConfirmOneOpen(false)
						break
				}
			} else {
				switch (true) {
					/** If profile location is null, cart location is null, show ConfirmOne from IP */
					case check(!profileCountry):
						// Open ConfirmOne Model
						console.log(`case5`)
						setConfirmModalProp({
							country: ipLocation && ipLocation.country_code ? ipLocation.country_code : cartCountry,
							region: ipLocation && ipLocation.region_code ? ipLocation.region_code : cartRegion,
						})
						setIsConfirmOneOpen(true)
						break
					case check(profileCountry && (profileCountry !== cartCountry || profileRegion !== cartRegion)):
						// Open ChooseBetween Modal
						console.log(`case6`)
						setChooseModalProp({
							countryOne: profileCountry,
							countryTwo: cartCountry,
							regionOne: profileRegion,
							regionTwo: cartRegion,
						})
						setIsChooseBetweenTwo(true)
						break
					default:
						// make sure model is closed
						console.log(`case7`)
						setIsChooseBetweenTwo(false)
						setIsConfirmOneOpen(false)
						break
				}
			}
		}
	}, [
		cartLocationFetching,
		profileLocationIsFetching,
		isFetchingCart,
		// cartData,
		countries,
		profileCountry,
		profileRegion,
		cartCountry,
		cartRegion,
		// ipLocation,
	])

	return (
		<>
			{isChooseBetweenTwo && (
				<ChooseBetweenModal
					// onClose={() => setIsChooseBetweenTwo(false)}
					onClose={() => setIsConfirmOneOpen(false)}
					isOpen={isChooseBetweenTwo}
					countryOne={chooseModalProp.countryOne}
					countryTwo={chooseModalProp.countryTwo}
					regionOne={chooseModalProp.regionOne}
					regionTwo={chooseModalProp.regionTwo}
				/>
			)}
			{isConfirmOneOpen && (
				<ConfirmOneModal
					// onClose={() => setIsConfirmOneOpen(false)}
					onClose={() => setIsConfirmOneOpen(false)}
					isOpen={isConfirmOneOpen}
					country={confirmModalProp.country}
					region={confirmModalProp.region}
				/>
			)}
		</>
	)
}

export default LocationManager
