import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useCheckoutContext } from '../../../../contexts/CheckoutProvider'
import { retrieveVideoProduct } from '../../../../../api/checkout/utils'
import { addItemToCart, removeItemFromCart } from '../../../../../api/checkout/actions'
import { setVideoProduct } from '../../../../../reducers/checkout/cart'
import { useEventContext } from '../../../../contexts/EventProvider'
import { useMedia } from '../../../../hooks/useMedia'
import { useVideoDurationCalc } from '../../../../hooks/useVideoDurationCalc'

/**
 * Hook used to set and manage state of the event being retrieved for the checkout
 * This is where we calculate duration, etc...
 *
 * @returns
 */
const useCheckoutEventSetup = () => {
	/** Retrieve Dispatch */
	const dispatch = useDispatch()
	/** Retrieve history hook */
	const navigate = useNavigate()
	const { event } = useEventContext()
	const media = useMedia()
	const { durationSec } = useVideoDurationCalc(media, event.themeTemplateId)

	const {
		productsArray: products,
		cart: { data: cart },
	} = useCheckoutContext()

	/**
	 * Set some variables based on the event we receive
	 * */
	useEffect(() => {
		/** Scroll to the top if event has been paid */
		if (event.paid == true) {
			if (window) {
				window.scrollTo(0, 0)
			}
			navigate(`/event/${event.uuid}/checkout/successful`)
		}
	}, [event])

	/**
	 * Update cart video items
	 */
	useEffect(() => {
		/** First, check if we have products and that items of the cart are available */
		if (products.length > 0 && cart && cart.items) {
			/** Filter all video products */
			const videoProducts = products.filter((el) => el.group == 'video')
			/** Retrieve the video product corresponding to the duration */
			const currentProduct = retrieveVideoProduct(videoProducts, durationSec)
			/** Check if this product is contained in the cart */
			let itemAlreadyPresent = currentProduct ? cart.items.indexOf(currentProduct.id) != -1 : -1
			/** Check if other video products are in the cart */
			videoProducts.forEach((el, i) => {
				/** If is is in the cart AND isn't the video product we found matching the video length */
				if (cart.items.indexOf(el.id) != -1 && el.id != currentProduct.id) {
					dispatch(removeItemFromCart(el.id))
				}
			})

			if (currentProduct) {
				/** Add the new video product to cart if not yet present */
				!itemAlreadyPresent && dispatch(addItemToCart(currentProduct.id))
				/** Save a reference to the video product on the cart */
				dispatch(setVideoProduct(currentProduct))
			} else {
				/** Reset active video product */
				dispatch(setVideoProduct(null))
			}
		}
	}, [durationSec, products, cart])
}

export default useCheckoutEventSetup
