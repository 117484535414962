import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'
import { useCheckoutContext } from '../../../../../contexts/CheckoutProvider'
import { geo } from '../../../../../../../client/config.client'
import { updateCart } from '../../../../../../api/checkout/actions'
import { updateProfile } from '../../../../../../api/app/profile'
import Card from '../../../../atoms/Card'
import useProfile from '../../../../../hooks/useProfile'

const ConfirmOneModal = ({ onClose, isOpen, country, region }) => {
	const dispatch = useDispatch()

	/** Retrieve user profile */
	const profile = useProfile()
	// const { id } = useSelector((s) => s.auth.profile, shallowEqual)
	const {
		checkout: {
			location: { countries },
		},
	} = useCheckoutContext()
	const [countryData, setCountryData] = useState()

	useEffect(() => {
		if (countries) {
			setCountryData(countries[country])
		}
	}, [countries])

	const handleNo = useCallback(() => {
		const id = profile ? profile.id : null
		/** Save on the cart to default country */
		dispatch(updateCart({ countryCode: geo.defaultCountryCode, regionCode: geo.defaultRegionCode }))
		/** Save on the profile to default country */
		dispatch(updateProfile({ id: id, countryCode: geo.defaultCountryCode, regionCode: geo.defaultRegionCode }))
		onClose()
	}, [])

	const handleYes = useCallback(() => {
		/** Save on the cart */
		dispatch(updateCart({ countryCode: country, regionCode: region }))
		onClose()
	}, [])

	const handleYesAndSave = useCallback(() => {
		const id = profile ? profile.id : null
		/** Save on the cart */
		dispatch(updateCart({ countryCode: country, regionCode: region }))
		/** Save on the profile */
		dispatch(updateProfile({ id: id, countryCode: country, regionCode: region }))
		onClose()
	}, [])

	return (
		<Modal onClose={() => {}} isOpen={isOpen} isCentered={true} size="2xl">
			<ModalOverlay />
			<ModalContent>
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={'/assets/images/graphics/vidday-payment-globe.svg'} alt="Location" boxSize="75px" />
						<Heading fontSize="1.7rem" variant="hero">
							Is this your country?
						</Heading>
						<Text as="strong">We've detected that you are in {countryData?.name}.</Text>
						<Text>Help us set the proper country for you.</Text>
						<Card w="40%" p="0.5rem" alignItems="center">
							<Image src={countryData?.flag} alt={countryData?.name} boxSize="60px" />
							<Text fontSize="0.85rem">
								{countryData?.name} {/* - ({countryData?.defaultCurrencyCode}) */}
							</Text>
						</Card>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button size="sm" minW="5rem" onClick={handleNo}>
							No
						</Button>
						<Button size="sm" onClick={handleYesAndSave}>
							YES, remember this
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ConfirmOneModal
