import React from 'react'
import { chakra, Spinner, Flex, Text } from '@chakra-ui/react'
import { useCheckoutContext } from '../../../../../contexts/CheckoutProvider'
import { formatToDecimalAmount } from '../../../../../../api/checkout/utils'
import { useEventContext } from '../../../../../contexts/EventProvider'

const OccasionDiscount = () => {
	const {
		event: { occasion },
	} = useEventContext()
	const {
		cartCalculations: { occasionDiscount },
		currency,
		cart: { isFetching },
	} = useCheckoutContext()

	const value = !isFetching ? (
		`-${formatToDecimalAmount(occasionDiscount, currency.symbol, currency.code)}`
	) : (
		<Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
	)

	return (
		<Flex w="full" justifyContent="space-between">
			<Text>
				Free occasion "{occasion}" - <chakra.strong>100% Discount</chakra.strong> (for video gift only).
			</Text>
			<Text>{value}</Text>
		</Flex>
	)
}

export default OccasionDiscount
