import React, { useEffect, useState } from 'react'
import { chakra, Box, Spinner, Flex, Link, Text, Checkbox, useDisclosure } from '@chakra-ui/react'
import { useCheckoutContext } from '../../../../../contexts/CheckoutProvider'
import { formatToDecimalAmount } from '../../../../../../api/checkout/utils'
import { QualityCheckModal } from '../../../../organisms/ModalQualityCheck'
import { useDispatch } from 'react-redux'
import { addItemToCart, removeItemFromCart } from '../../../../../../api/checkout/actions'
import { useAuthContext } from '../../../../../contexts/AuthProvider'

const RowQualityCheck = () => {
	/** Retrieve dispatch method */
	const dispatch = useDispatch()
	const { onClose, onOpen, isOpen } = useDisclosure()
	const {
		cart: { data: cartData },
		productsArray,
		currency,
	} = useCheckoutContext()
	const { hasSubscription } = useAuthContext() // used with subscription
	const [selected, setSelected] = useState(false)
	const [qcProduct, setQcProduct] = useState({})
	const [qcDelayMsg, setQcDelayMsg] = useState('')

	const displayQualityCheckDelay = () => {
		const date = new Date()
		const currentHour = date.getUTCHours()
		let hr
		// display between 9 pm CST and 7 am CST ( 2am UTC to 12pm UTC )
		if (currentHour >= 2 && currentHour <= 12) {
			hr = 13 - currentHour
			setQcDelayMsg(`⏳ You're in the queue. NOTE: Quality check can add up to ${hr} HOURS of processing.`)
		} else {
			setQcDelayMsg('')
		}
	}

	const handleChange = ({ target: { checked } }) => {
		if (qcProduct && qcProduct.id) {
			checked ? dispatch(addItemToCart(qcProduct.id)) : dispatch(removeItemFromCart(qcProduct.id))
		}
		setSelected(checked)
	}

	const price =
		qcProduct && qcProduct.price && qcProduct.price > 0 ? (
			<Text color={selected ? 'inherit' : '#B5BCC3'}>
				{formatToDecimalAmount(qcProduct.price, currency.symbol, currency.code)}
			</Text>
		) : (
			<Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
		)

	useEffect(() => {
		if (cartData?.availableAddons?.length > 0) {
			/** Filter only add-ons products */
			const qcProduct = cartData.availableAddons.find((a) => {
				return a.slug == 'qualityCheck'
			})
			if (qcProduct) {
				setQcProduct(qcProduct)
			}
		}
	}, [cartData?.availableAddons])

	useEffect(() => {
		if (cartData !== null && cartData.items.length > 0 && Object.keys(qcProduct).length !== 0) {
			/** get QcProduct id */
			const qcProductId = qcProduct.id
			const qcStatus = cartData.items.includes(qcProductId)
			setSelected(qcStatus)
		}
	}, [cartData, qcProduct])

	useEffect(() => {
		if (selected) {
			displayQualityCheckDelay()
		} else {
			setQcDelayMsg('')
		}
	}, [selected])

	return (
		<>
			{qcProduct && (
				<>
					<Box w="full">
						<Flex justifyContent="space-between">
							<Checkbox onChange={handleChange} iconSize="1rem" isChecked={selected}>
								<chakra.strong>Quality Check + AI Image Upscaling</chakra.strong>
							</Checkbox>
							{price}
						</Flex>
						<Text fontSize="0.75rem">
							Get a VidDay specialist to personally review and edit your media.{' '}
							<Link onClick={onOpen} color="link">
								Learn More
							</Link>
						</Text>
						<Text fontSize="0.75rem" fontWeight="semibold">
							{qcDelayMsg}
						</Text>
					</Box>
					<QualityCheckModal onClose={onClose} isOpen={isOpen} />
				</>
			)}
		</>
	)
}

export default RowQualityCheck
