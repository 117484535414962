import { useState, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

/**
 * Hook to obtain information about the event/video duration, etc...
 * @returns
 */
const useIsWorkingServerSide = (event) => {
	const [state, setState] = useState({
		isAnimating: false,
		key: 0,
	})

	/** Retrieve event state */
	const isCartFetching = useSelector((s) => s.checkout.cart.isFetching, shallowEqual)
	// const isCartFetching = useSelector((s) => s.checkout.cart.isFetching, shallowEqual)
	// const isCartFetching = useSelector((s) => s.checkout.cart.isFetching, shallowEqual)

	useEffect(() => {
		let isAnimating = isCartFetching

		if (isAnimating) {
			setState((prevState) => ({
				isAnimating: true,
				key: prevState.isAnimating ? prevState.key : prevState.key ^ 1,
			}))
		} else {
			setState((prevState) => ({
				isAnimating: false,
				key: prevState.isAnimating ? prevState.key : prevState.key ^ 1,
			}))
		}

		// setState((prevState) => ({
		//     isAnimating: !prevState.isAnimating,
		//     key: prevState.isAnimating ? prevState.key : prevState.key ^ 1,
		// }))
	}, [isCartFetching])

	/** Return the cart state from Redux */
	return state
}

export default useIsWorkingServerSide
