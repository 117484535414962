import React from 'react'
import { Box, Heading, Image, Text, VStack } from '@chakra-ui/react'
import useImageGenerator from '../../../../../hooks/useImageGenerator'
import { useAuthContext } from '../../../../../contexts/AuthProvider'

const FinalizeFooter = () => {
	const img = useImageGenerator({
		path: '/assets/images/graphics/vidday-preview-feature-plants',
		ext: 'png',
	})

	const { hasSubscription } = useAuthContext()

	return (
		<VStack spacing="2rem">
			<Box align="center" px="1rem">
				<Text variant="title">Your Event Page will be closed to new submissions when finalized.</Text>
				<Text>
					You can reopen your Event Page to edit{' '}
					{!hasSubscription && (
						<>
							— the first remake is free, while subsequent ones incur a <strong>30% fee</strong>.
						</>
					)}
				</Text>
			</Box>
			<Box align="center" px="2rem">
				<Image sx={{ transform: 'translate(1rem, 0)' }} mb="1rem" src={img.src} srcSet={img.srcset} />
				<Heading mb=".5rem" size="md">
					100K+ trees planted.
				</Heading>
				<Text>
					A portion of our net proceeds is donated to planting trees in British Columbia, California,
					Australia, and the Amazon Rainforest.
				</Text>
			</Box>
		</VStack>
	)
}

export default FinalizeFooter
