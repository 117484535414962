import React, { useState } from 'react'
import {
	Text,
	VStack,
	HStack,
	Divider,
	Badge,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	Box,
	chakra,
	Flex,
} from '@chakra-ui/react'
import { TimerRounded } from '../../../../atoms/Icon'
import { useCheckoutContext } from '../../../../../contexts/CheckoutProvider'
import { formatToDecimalAmount } from '../../../../../../api/checkout/utils'
import useProductsSetup from '../../hooks/useProductsSetup'

const durations = [
	{ duration: '0:00 – 3:00' },
	{ duration: '3:01 – 10:00', isAverage: true },
	{ duration: '10:01 – 20:00' },
	{ duration: '20:01 – 30:00' },
	{ duration: '30:01 – 170:00' },
]

const PriceListItem = ({ item, currency }) => {
	return (
		<HStack w="full" justify="space-between">
			<Flex w="full" alignItems={['left', 'center']} flexDirection={['column', 'row']}>
				<HStack>
					<TimerRounded width="1.125rem" />
					<Text>{item.duration}</Text>
				</HStack>
				{item.isAverage && (
					<Box>
						<Badge colorScheme="green" ml="1.5rem">
							Avg. Length
						</Badge>
					</Box>
				)}
			</Flex>
			<Text width="160px" textAlign="right">
				{/* Use this when pricing changes {formatToDecimalAmount(item.price, currency.symbol, currency.code)} */}
				<strong>
					{currency.symbol}
					{item.price / 100}{' '}
				</strong>{' '}
				{currency.code}
			</Text>
		</HStack>
	)
}

const PricingTable = () => {
	/** Retrieve checkout location and cart state */
	const {
		checkout: {
			products: { data },
		},
		currency,
	} = useCheckoutContext()

	// Map Video Products from Stripe
	let products = [...data]
	let sortedProducts = products.sort((a, b) => a.pricing.price - b.pricing.price)
	let videoProducts = sortedProducts.filter((p) => p.metadata.group == 'video' && p.active)
	const priceList = videoProducts
		.map((p, i) => {
			if (p.metadata.group == 'video' && p.active) {
				return {
					price: p.pricing.price,
					...durations[i],
				}
			}
			return null
		})
		.filter(Boolean)

	return (
		currency.code !== 'INR' && (
			<Accordion
				allowToggle
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					w: 'calc(100% - 4rem)',
					border: '1px solid',
					borderColor: 'gray.300',
					borderRadius: '2xl',
					flexDirection: 'column',
					pos: 'relative',
					mt: '-3rem',
					mx: 'auto',
					mb: '3rem',
					p: '1.5rem',
					pt: '2rem',
					zIndex: 0,
				}}>
				<AccordionItem w="full" border="none">
					<AccordionButton color="link" fontSize="1rem" justifyContent="center" alignItems="center">
						See price chart <AccordionIcon />
					</AccordionButton>

					<AccordionPanel>
						<VStack
							pt="1rem"
							w="full"
							spacing={'1rem'}
							divider={<Divider borderColor="rgb(182, 202, 227)" />}>
							{priceList.map((item, i) => {
								if (item.duration) {
									return <PriceListItem item={item} currency={currency} key={i} index={i} />
								}
							})}
						</VStack>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		)
	)
}

export default PricingTable
