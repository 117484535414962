import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, VStack, Heading, Image, Text, HStack, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react'
import { useCheckoutContext } from '../../../../../contexts/CheckoutProvider'
import { updateCart } from '../../../../../../api/checkout/actions'
import { updateProfile } from '../../../../../../api/app/profile'
import { useAuthContext } from '../../../../../contexts/AuthProvider'
import Card from '../../../../atoms/Card'
import useProfile from '../../../../../hooks/useProfile'

const ChooseBetweenModal = ({ onClose, isOpen, countryOne, countryTwo, regionOne, regionTwo }) => {
	const dispatch = useDispatch()
	/** Retrieve user profile */
	const profile = useProfile()
	const {
		checkout: {
			location: { countries },
		},
	} = useCheckoutContext()
	const [countryOneData, setCountryOneData] = useState()
	const [countryTwoData, setCountryTwoData] = useState()
	const changeInfo = countryOne == countryTwo ? 'region' : 'country'

	useEffect(() => {
		if (countries) {
			setCountryOneData(countries[countryOne])
			setCountryTwoData(countries[countryTwo])
		}
	}, [countries])

	const handleCountryOne = useCallback(() => {
		const id = profile ? profile.id : null
		/** Save on the cart */
		dispatch(updateCart({ countryCode: countryOne, regionCode: regionOne }))
		/** Save on the profile */
		dispatch(updateProfile({ id: id, countryCode: countryOne, regionCode: regionOne }))

		onClose()
	}, [])

	const handleCountryTwo = useCallback(() => {
		const id = profile ? profile.id : null
		/** Save on the cart */
		dispatch(updateCart({ countryCode: countryTwo, regionCode: regionTwo }))
		/** Save on the profile */
		dispatch(updateProfile({ id: id, countryCode: countryTwo, regionCode: regionTwo }))

		onClose()
	}, [])

	return (
		<Modal onClose={() => {}} isOpen={isOpen} isCentered={true} size="2xl">
			<ModalOverlay />
			<ModalContent>
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={'/assets/images/graphics/vidday-payment-globe.svg'} alt="Location" boxSize="75px" />
						<Heading fontSize="1.7rem" variant="hero">
							Select your {changeInfo}
						</Heading>
						<Text as="strong">We've detected a different {changeInfo} from the one in your profile.</Text>
						<Text>Help us set the proper {changeInfo} for you.</Text>
					</VStack>
					<HStack spacing="1rem" justify="center" mb="0.2rem">
						{regionOne && (
							<Card w="40%" p="0.5rem" alignItems="center" cursor="pointer" onClick={handleCountryOne}>
								<Image src={countryOneData?.flag} alt={countryOneData?.name} boxSize="60px" />
								<Text fontSize="0.85rem">
									{countryOneData?.name} ({regionOne})
								</Text>
							</Card>
						)}
						<Card w="40%" p="0.5rem" alignItems="center" cursor="pointer" onClick={handleCountryTwo}>
							<Image src={countryTwoData?.flag} alt={countryTwoData?.name} boxSize="60px" />
							<Text fontSize="0.85rem">
								{countryTwoData?.name} {regionTwo && `(${regionTwo}) `}
							</Text>
						</Card>
					</HStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default ChooseBetweenModal
