import React from 'react'
import { chakra, Spinner, Flex, Text } from '@chakra-ui/react'
import { formatToDecimalAmount } from '../../../../../../api/checkout/utils'
import { useCheckoutContext } from '../../../../../contexts/CheckoutProvider'
import { customThemeCoverSlug } from '../../../../../../../client/config.client'

const RowThemeCover = () => {
	const {
		cart: { isFetching, lineItems },
		currency,
	} = useCheckoutContext()

	const lineItem = lineItems.find((item) => item.slug == customThemeCoverSlug)

	const price =
		!isFetching && lineItem ? (
			formatToDecimalAmount(lineItem.price, currency.symbol, currency.code)
		) : (
			<Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="blue.500" size="md" />
		)
	return (
		<Flex w="full" justifyContent="space-between">
			<Text>
				<chakra.strong>Custom intro</chakra.strong>
			</Text>
			<Text>{price}</Text>
		</Flex>
	)
}

export default RowThemeCover
