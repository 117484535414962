import React from 'react'
import { chakra, Spinner, Flex, Text } from '@chakra-ui/react'
import { useCheckoutContext } from '../../../../../contexts/CheckoutProvider'
import { formatToDecimalAmount } from '../../../../../../api/checkout/utils'

const SubscriptionDiscount = () => {
	const {
		cartCalculations: { subscriptionDiscount },
		currency,
		cart: { isFetching },
		event: { subscription },
	} = useCheckoutContext()

	const regexPattern = /^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/
	const subscriptionName = regexPattern.test(subscription) ? (
		<Text>
			<chakra.strong>Membership - 100% Discount</chakra.strong>
		</Text>
	) : (
		<Text>
			{subscription} - <chakra.strong>100% Discount</chakra.strong>
		</Text>
	)

	const value = !isFetching ? (
		`- ${formatToDecimalAmount(subscriptionDiscount, currency.symbol, currency.code)}`
	) : (
		<Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
	)

	return (
		<Flex w="full" justifyContent="space-between">
			{subscriptionName}
			<Text>
				<chakra.strong>{value}</chakra.strong>
			</Text>
		</Flex>
	)
}

export default SubscriptionDiscount
