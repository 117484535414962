import React, { useEffect, useState } from 'react'
import { Box, chakra, Spinner, Flex, Text, Button } from '@chakra-ui/react'
import { useCheckoutContext } from '../../../../../contexts/CheckoutProvider'
import { formatToDecimalAmount } from '../../../../../../api/checkout/utils'

const RowPromoDiscount = () => {
	const {
		cartCalculations: { appliedDiscount },
		currency,
		cart: { isFetching },
		promotion: {
			promotionState,
			promotionState: { isValid, data: promo, dataServer: serverPromo },
			removePromoCode,
		},
	} = useCheckoutContext()
	const [formatedDiscInfo, setFormattedDiscInfo] = useState(null)

	const value = !isFetching ? (
		formatToDecimalAmount(appliedDiscount, currency.symbol, currency.code)
	) : (
		<Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
	)

	const handleSubmit = () => {
		removePromoCode()
	}

	useEffect(() => {
		if ((promo && isValid) || serverPromo) {
			const suffix = ' Off'
			switch (serverPromo.type) {
				case 'percent':
					setFormattedDiscInfo(`${serverPromo.value}% ${suffix}`)
					break
				case 'amount':
					const value = formatToDecimalAmount(serverPromo.value, currency.symbol, currency.code)
					setFormattedDiscInfo(value + suffix)
					break
				case 'gratuit':
					setFormattedDiscInfo(`Free`)
					break
			}
		}
	}, [isValid, promo, serverPromo])

	return (
		<Box w="full">
			<Flex justifyContent="space-between">
				<Text>Discount</Text>
				<Text>- {value}</Text>
			</Flex>
			<Text fontSize="0.75rem">
				<chakra.strong>{formatedDiscInfo}</chakra.strong> – {serverPromo?.code}
			</Text>
			<Flex>
				<Button colorScheme="red" height="1.5rem" size="xs" onClick={handleSubmit}>
					Remove
				</Button>
			</Flex>
		</Box>
	)
}

export default RowPromoDiscount
