import { useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { setIsRepublish } from '../../../../../reducers/checkout/event'

/**
 * Hook used to determine if the event is a republish by looping through available invoices.
 * @returns
 */
const useIsRepublish = () => {
	/** Retrieve Dispatch */
	const dispatch = useDispatch()
	/** Retrieve all invoices */
	const allInvoices = useSelector((s) => s.entities.invoices.entities, shallowEqual)
	const event = useSelector((s) => s.event.event, shallowEqual)

	const [isRepublish, setIsRepublishState] = useState(false) // assume isRepublish false by default
	const [isAllowedRepublish, setIsAllowedRepublish] = useState(false) // assume isRepublish false by default

	const allowedRepublishes = 1 // set the amount of republishes allowed before repayment

	var invoices = []

	useEffect(() => {
		if (event && allInvoices) {
			if (Object.keys(allInvoices).length > 0) {
				Object.keys(allInvoices).forEach((key) => {
					if (allInvoices[key].eventId == event.id) {
						if (
							allInvoices[key].chargeId &&
							(allInvoices[key].chargeId.includes('ch_') || allInvoices[key].gratuit)
						) {
							invoices[key] = allInvoices[key]
						}
					}
				})
				//console.log("Invoices for event", Object.keys(invoices).length)
				/** Update the isRepublish */
				dispatch(setIsRepublish(Object.keys(invoices).length > allowedRepublishes))
				setIsRepublishState(Object.keys(invoices).length > allowedRepublishes)
				setIsAllowedRepublish(
					Object.keys(invoices).length > 0 && Object.keys(invoices).length <= allowedRepublishes
				)
			}
		}
	}, [event, allInvoices])

	return { isRepublish: isRepublish, isAllowedRepublish: isAllowedRepublish }
}

export default useIsRepublish
