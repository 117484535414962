import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getCart, getLocation } from '../../../../../api/checkout/actions'
import { setIsFree } from '../../../../../reducers/checkout/event'
import { shouldVideoBeFree } from '../../../../../api/checkout/utils'
import { useEventContext } from '../../../../contexts/EventProvider'

/**
 * Hook/Side effects to retrieve products when the event changes
 * @returns
 */
const useCartSetup = () => {
	/** Retrieve Dispatch */
	const dispatch = useDispatch()

	/** Retrieve event Id */
	const {
		event: { id: eventId, occasion },
	} = useEventContext()

	useEffect(() => {
		/** Retrieve user's iplocation */
		dispatch(getLocation())
		/** Check if it is a free event */
		const isFree = shouldVideoBeFree(occasion)
		dispatch(setIsFree(isFree))
	}, [occasion])

	/**
	 * Fetch the user's cart for this event ID (and whenever the cart changes)
	 **/
	useEffect(() => {
		/** Get Cart */
		dispatch(getCart({ eventId: eventId }))
	}, [eventId])
}

export default useCartSetup
