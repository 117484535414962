import React, { useEffect, useState } from 'react'
import { chakra, Box, Spinner, Flex, Link, Text, Checkbox, useDisclosure } from '@chakra-ui/react'
import { useCheckoutContext } from '../../../../../contexts/CheckoutProvider'
import { formatToDecimalAmount } from '../../../../../../api/checkout/utils'
import { useDispatch } from 'react-redux'
import { addItemToCart, removeItemFromCart } from '../../../../../../api/checkout/actions'
import { PlantTreeModal } from '../../../../organisms/ModalPlantTree'

const RowQualityCheck = () => {
	/** Retrieve dispatch method */
	const dispatch = useDispatch()
	const { onClose, onOpen, isOpen } = useDisclosure()
	const {
		cart: { data: cartData },
		currency,
	} = useCheckoutContext()
	const [selected, setSelected] = useState(false)
	const [plantTreeProduct, setPlantTreeProduct] = useState({})

	const handleChange = ({ target: { checked } }) => {
		if (plantTreeProduct && plantTreeProduct.id) {
			checked ? dispatch(addItemToCart(plantTreeProduct.id)) : dispatch(removeItemFromCart(plantTreeProduct.id))
		}
		setSelected(checked)
	}

	const price =
		plantTreeProduct && plantTreeProduct.price && plantTreeProduct.price > 0 ? (
			<Text color={selected ? 'inherit' : '#B5BCC3'}>
				{formatToDecimalAmount(plantTreeProduct.price, currency.symbol, currency.code)}
			</Text>
		) : (
			<Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
		)

	useEffect(() => {
		if (cartData?.availableAddons?.length > 0) {
			/** Filter only add-ons products */
			const plantTreeProduct = cartData.availableAddons.find((a) => {
				return a.slug == 'plantTree'
			})
			if (plantTreeProduct) {
				setPlantTreeProduct(plantTreeProduct)
			}
		}
	}, [cartData?.availableAddons])

	useEffect(() => {
		if (cartData !== null && cartData.items.length > 0 && Object.keys(plantTreeProduct).length !== 0) {
			/** get QcProduct id */
			const plantTreeProductId = plantTreeProduct.id
			const plantTreeStatus = cartData.items.includes(plantTreeProductId)
			setSelected(plantTreeStatus)
		}
	}, [cartData, plantTreeProduct])

	return (
		<>
			{plantTreeProduct && (
				<>
					<Box w="full">
						<Flex justifyContent="space-between">
							<Checkbox onChange={handleChange} iconSize="1rem" isChecked={selected}>
								<chakra.strong>Plant 5 Trees 🌲</chakra.strong>
							</Checkbox>
							{price}
						</Flex>
						<Text fontSize="0.75rem">
							Help the planet on their behalf.{' '}
							<Link onClick={onOpen} color="link">
								Learn More
							</Link>
						</Text>
					</Box>
					<PlantTreeModal onClose={onClose} isOpen={isOpen} />
				</>
			)}
		</>
	)
}

export default RowQualityCheck
