import React from 'react'
import {
	Box,
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'

export const QualityCheckModal = ({ isOpen, onClose }) => {
	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image
							src={'/assets/images/graphics/quality-check-badge.png'}
							// srcSet={`/assets/images/graphics/quality-check-badge.png 1x, /assets/images/graphics/quality-check-badge@2x.png 2x`}
							alt="Quality Check"
							boxSize="60px"
						/>
						<Heading size="lg" variant="hero">
							Quality Check
						</Heading>
					</VStack>
					<Box display="grid" textAlign="center">
						<Text>Save time and have a VidDay specialist:</Text>
						<Text as="strong" mt="1rem">
							Trim video clips
						</Text>
						<Text>with awkward beginnings and endings</Text>
						<Text as="strong" mt="1rem">
							Upscale photos
						</Text>
						<Text>which are low-resolution using AI upscaling to make them sharper and clearer</Text>
						<Text as="strong" mt="1rem">
							Crop photos
						</Text>
						<Text>with blank areas</Text>
						<Text as="strong" mt="1rem">
							Toggle off background music
						</Text>
						<Text>from video clips that already have music</Text>
						<Text as="strong" mt="1rem">
							Increase feature time
						</Text>
						<Text>for photos with text groups</Text>
						<Text as="strong" mt="1rem">
							Exclude undesirable media
						</Text>
						<Text>from the background scenes (for Collage themes)</Text>
					</Box>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button onClick={onClose}>Close</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
