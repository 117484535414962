import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getProducts } from '../../../../../api/checkout/actions'
import { useCheckoutContext } from '../../../../contexts/CheckoutProvider'

/**
 * Setup Hook to retrieve products when country changes
 * @returns
 */
const useProductsSetup = () => {
	/** Retrieve Dispatch */
	const dispatch = useDispatch()
	/** Load country on the location state */
	const {
		location: { country },
	} = useCheckoutContext()

	/**
	 * Fetch available products based on the country when the country changes
	 **/
	useEffect(() => {
		if (country && country != '') {
			dispatch(getProducts(country))
		}
	}, [country])
}

export default useProductsSetup
