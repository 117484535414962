import React from 'react'
import { chakra, Spinner, Flex, Text } from '@chakra-ui/react'
import { useCheckoutContext } from '../../../../../contexts/CheckoutProvider'
import { formatToDecimalAmount } from '../../../../../../api/checkout/utils'

const RepublishDiscount = () => {
	const {
		cartCalculations: { republishDiscount },
		currency,
		cart: { isFetching },
		event: { isFreeRepublish },
	} = useCheckoutContext()

	const percent = isFreeRepublish ? `100%` : `70%`
	const value = !isFetching ? (
		`- ${formatToDecimalAmount(republishDiscount, currency.symbol, currency.code)}`
	) : (
		<Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
	)

	return (
		<Flex w="full" justifyContent="space-between">
			<Text>
				Republishing - <chakra.strong>{percent} Discount</chakra.strong>
			</Text>
			<Text>{value}</Text>
		</Flex>
	)
}

export default RepublishDiscount
